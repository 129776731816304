import { connect } from 'react-redux';
import { Dispatch } from "redux";
import React from 'react';
import { GameData } from '../api/ChessWebsiteGameFetchers/GamesFetcher';
import { analyzeGame, getPositionAtI } from './engineFunctions';

import { PositionEval, PositionReturn,  GameEvaluations } from './EngineObjects';
import { TrainingState } from '../reducers/trainingStateReducer';


const ENGINE_DEPTH: number = 20;

interface EngineState {
  pgnGames: GameData[];
  numThreads: number;
  finishedGames: GameEvaluations[];
  analysisStartMove: number;
  analysisEndMove: number;
  analyzedPositions: Map<string, string>;
  engineDepth: number;
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type ListContainerProps = StateProps & DispatchProps

class Engine extends React.Component<ListContainerProps, EngineState>{

  positionHashes: Map<string, string>;
  numGamesAnalyzed: number;
  pgnGames: GameData[] = [];
  analyzedPositions: Map<string, string> = new Map<string, string>();
  hasEventListener = false;

  constructor(props: any) {
    super(props);
    this.positionHashes = new Map<string, string>();
    this.numGamesAnalyzed = 0;

    this.state = {
      pgnGames: [],
      numThreads: Math.floor(navigator.hardwareConcurrency/2.0),
      finishedGames: [],
      analysisStartMove: 4,
      analysisEndMove: 30,
      analyzedPositions: new Map(),
      engineDepth: ENGINE_DEPTH
    }
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {

    //POSSIBLE TODO: setState is async.. so cant rlly expect eval to have correct values, its why we update class var
    if (this.props.games !== this.pgnGames && this.props.games.length > 0) {

      this.pgnGames = this.props.games;
      if(this.hasEventListener === false) {
        console.log("engine adding event listener");
        this.addEventListener();
        this.props.setTrainingState(TrainingState.ANALYZING_GAMES);
        this.hasEventListener = true;
      }

      console.log("engine: evaling all games");
      this.evalGame(this.state.numThreads);
    }
  }

  addEventListener() {
    window.addEventListener("message", (event) => {
      if(event.data.hasOwnProperty("dataType") && event.origin === window.location.origin && event.data.dataType === "pgnEval"){
          console.log("event heard engine!");

          const percentageAnalyzed: number = ((this.numGamesAnalyzed+1)/this.pgnGames.length)*100;
          this.numGamesAnalyzed = this.numGamesAnalyzed+1;
          console.log("setting percentageAnalyzed", percentageAnalyzed);
          this.props.setLoadingPercentage(percentageAnalyzed);
          if(this.numGamesAnalyzed >= this.pgnGames.length ){
              console.log("games analyzed >= this.pgnGames.length", event.data.evalNumbers);
              this.analyzedPositions = event.data.evalNumbers;
              this.getPositionEvals();
          }
          else if(this.numGamesAnalyzed%this.state.numThreads === 0){
              this.evalGame(this.state.numThreads);
          }
      }
    }, false);
  }

  getPositionEvals(){

    //TODO edit this.state.finished games
    const gameEvals: GameEvaluations[] = this.pgnGames.map(pgnGame => {return {
      pgnData: pgnGame,
      positionEvals: this.getPositionEvalsFromGame(pgnGame)
    }});

    this.setState({
        finishedGames: gameEvals
    });

    console.log("eval_finished");

    this.props.setEvaluations(gameEvals);
  }

  getPositionEvalsFromGame(pgnGame: GameData): PositionEval[] {
    let gamePositionEvals: PositionEval[] = [];
      for(let i = this.state.analysisStartMove; i< this.state.analysisEndMove;i++){
          const pgnPos: PositionReturn = getPositionAtI(pgnGame, i);
          if(pgnPos.position !== "none"){

              const thisFinPos: PositionEval = {
                  position: pgnPos.position,
                  eval: Number(this.analyzedPositions.get(pgnPos.position)),
                  myMove: pgnPos.moveUci,
                  moveNumber: i
              }
              gamePositionEvals.push(thisFinPos);
          }
      }

      return gamePositionEvals;
  }

  async evalGame(numGamesParallel: number){
    console.log("theThreads: ", this.state.numThreads);

    if(this.numGamesAnalyzed >= this.pgnGames.length ){
      console.log("finished!: ", this.state.numThreads);
      this.props.setTrainingState(TrainingState.ANALYZING_MISTAKES);
    }
    else{
        //TODO:
        var i = this.numGamesAnalyzed;
        while(i < this.pgnGames.length && i < (this.numGamesAnalyzed+numGamesParallel)){
            const workerOptions: WorkerOptions = {
                name: `ThreadID${i}`
            }
            console.log("Engine.tsx analyzing a game");
            //await analyzeGame(new Worker('/stockfish-mv-worker.js', workerOptions), this.pgnGames[i], this.state.analysisStartMove, this.state.analysisEndMove, this.state.engineDepth, false, i);
            //In other project its ./testWorker.js so who knows :)
            await analyzeGame(new Worker('/testWorker.js', workerOptions), this.pgnGames[i], this.state.analysisStartMove, this.state.analysisEndMove, this.state.engineDepth, false, i);
            i = i+1;
        }
    }
  }

  render() {
    return (null);
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
      setEvaluations: (evals: any[]) => dispatch({type: 'SETEVALS', payload: evals}),
      setLoadingPercentage: (percentageAnalyzed: number) => dispatch({type: 'SET_LOADING_PERCENTAGE', payload: percentageAnalyzed}) ,
      setTrainingState: (trainingState: TrainingState) => dispatch({type: 'SET_TRAINING_STATE', payload: trainingState}),
  }
};

function mapStateToProps(state: any) {
  const { games } = state
  return { games: games }
}

export default connect(mapStateToProps, mapDispatchToProps)(Engine)