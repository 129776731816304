import { Button, Grid, Header, Input,Form, Radio, CheckboxProps, Divider, Icon, Modal} from 'semantic-ui-react'
import React, { MouseEvent } from 'react';
import './IntroModal.css';
import { ChessWebsite } from '../../pages/trainer/ChessTrainer';

import ChessComGamesFetcher from '../../api/ChessWebsiteGameFetchers/ChessComGamesFetcher';
import LichessGamesFetcher from '../../api/ChessWebsiteGameFetchers/LichessGamesFetcher';
import { GamesFetcher, GameData } from '../../api/ChessWebsiteGameFetchers/GamesFetcher';

import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { User } from '../../reducers/userReducer';
import { padEnd } from 'lodash';
import { hover } from '@testing-library/user-event/dist/hover';

interface IntroModalState {
	chessWebsite: ChessWebsite;
	showCard: boolean;
	userName: string;
	numGames: number;
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type ListContainerProps = StateProps & DispatchProps

//class IntroModal extends React.Component<any, IntroModalState>
class IntroModal extends React.Component<ListContainerProps, IntroModalState>{

	siteMap: Map<ChessWebsite, GamesFetcher>;

	constructor(props: any) {
        super(props);
		this.getMainMenuModal = this.getMainMenuModal.bind(this);
		this.handleCardXButtonClick = this.handleCardXButtonClick.bind(this);
		this.handleGoButtonClicked = this.handleGoButtonClicked.bind(this);
		this.getMainMenuModal = this.getMainMenuModal.bind(this);
		this.handleRadioChange = this.handleRadioChange.bind(this);
		this.handleUserNameChange = this.handleUserNameChange.bind(this);
		this.handleStartButtonClick = this.handleStartButtonClick.bind(this);

		this.state = {
			chessWebsite: ChessWebsite.CHESSCOM,
			showCard: true,
			userName: "",
			numGames: 100
		}
		this.siteMap = new Map([
            [ChessWebsite.CHESSCOM, new ChessComGamesFetcher() as GamesFetcher],
            [ChessWebsite.LICHESS, new LichessGamesFetcher() as GamesFetcher],
        ]);
    }
	
    render() {

		const mainMenu = this.getMainMenuModal();

		return (
			<Modal
				closeIcon
				open={this.state.showCard}
				onClose={() => this.handleStartButtonClick(false)}
				dimmer='blurring'
				>
				<Header icon='chess knight' content="Let's Get Training!" ></Header>
				<Modal.Content>
					{mainMenu}
				</Modal.Content>
				<Modal.Actions>
					<Button className='start-training' color='green' onClick={() => this.handleGoButtonClicked(this.state.userName, this.state.chessWebsite)} style={{width: '100%', margin: '0px auto 4px auto'}}>
						<Icon name='play' /> Start
					</Button>
				</Modal.Actions>
			</Modal>
		
		)
    }

	async handleGoButtonClicked(username: string, chessWebsite: ChessWebsite) {
		const games: GameData[] = await this.siteMap.get(chessWebsite)!.fetchGames(username, this.state.numGames);

		this.props.setUser({username: username, chessSite: chessWebsite});
		this.props.setChessGames(games);
		this.setState({
            showCard: false
        });
	}

	async handleStartButtonClick(isOpen: boolean) {
		this.setState({
            showCard: false
        });
	}

	async handleCardXButtonClick(event: MouseEvent){
        this.setState({
            showCard: false
        });
    }

	async handleRadioChange(e: React.FormEvent<HTMLInputElement>, value: CheckboxProps){
        this.setState({chessWebsite: value.value as ChessWebsite});
    }

	async handleUserNameChange(e: React.FormEvent<HTMLInputElement>) {
        const newValue = e.currentTarget.value;
        this.setState({
            userName: newValue
        });
    }

	getMainMenuModal(){
		return (
			<Grid.Column>
				<Grid.Row>
					<Form>
						<Form.Field>
							<Header as='h5'>Select your chess playing website:</Header>
						</Form.Field>
						<Form.Field>
							<Radio
								label='Chess.com'
								name='radioGroup'
								value={ChessWebsite.CHESSCOM}
								checked={this.state.chessWebsite === ChessWebsite.CHESSCOM}
								onChange={this.handleRadioChange}
							/>
						</Form.Field>
						<Form.Field>
							<Radio
								label='Lichess.org'
								name='radioGroup'
								value={ChessWebsite.LICHESS}
								checked={this.state.chessWebsite === ChessWebsite.LICHESS}
								onChange={this.handleRadioChange}
							/>
						</Form.Field>
					</Form>
				</Grid.Row>

				<Divider horizontal>

				</Divider>

				<Grid.Row>
					<Header as='h5'>Enter your username:</Header>
					<Input style={{width: '60%'}} placeholder='Username' onChange={this.handleUserNameChange} value={this.state.userName}></Input>
				</Grid.Row>

			</Grid.Column>
		)
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setUser: (user: User) => dispatch({ type: 'SET_USER', payload: user}),
		setChessGames: (chessGames: GameData[]) => dispatch({ type: 'SET_GAMES', payload: chessGames})
    }
};


function mapStateToProps(state: any) {
    const { user } = state
    return { user: user.username, chessSite: user.chessSite }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroModal)