import React, {useContext, useState} from "react";
import { connect } from 'react-redux';
import { Dispatch } from "redux";
//components
import { LinkButton } from "../../components/Button/ButtonElements";
import Navbar from "../../components/Navbar/index";
import { Input, Icon, Form, Button } from "semantic-ui-react";
import signUpIcon from "../../images/icons/signUpChess.png";


//elements
import {
	NavContainer,
	SignInForm,
	SignInHeader,
	InputWrapper,
	EmailInput,
	PasswordInput,
	VisibilityIcon,
	CheckboxWrapper,
	Checkbox,
	KeepMeSignedIn,
	BottomWrapper,
	LinksWrapper,
	ForgotUsername,
	ForgotPassword,
	ButtonWrapper,
} from "./SignInElements";

import {
    WholeSignInContainer,
	SignInContainerLeft,
    SignInContainerRight,
} from "../sign-up/SignUpElements";

import UserPool from "../../auth/UserPool";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";

interface SignInPageState {
	email: string,
	password: string,
	passwordVisible: boolean,
}


type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type ListContainerProps = StateProps & DispatchProps

class SignInPage extends React.Component<ListContainerProps, SignInPageState> {

	constructor(props: ListContainerProps) {
		super(props);
		this.state = {
			email: '',
			password: '',
			passwordVisible: false,
		}

		this.signIn = this.signIn.bind(this);
		this.handleEmailChanged = this.handleEmailChanged.bind(this);
		this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
		this.handleShowPassword = this.handleShowPassword.bind(this);
	}

	async handleEmailChanged(e: React.FormEvent<HTMLInputElement>) {
        const newValue = e.currentTarget.value;
        this.setState({
            email: newValue
        });
    }

    async handlePasswordChanged(e: React.FormEvent<HTMLInputElement>) {
        const newValue = e.currentTarget.value;
        this.setState({
            password: newValue
			
        });

    }

	async handleShowPassword() {
		this.setState({
			passwordVisible: !this.state.passwordVisible
		}
		)
	}

	signIn(e: any) {
		e.preventDefault();

		const user = new CognitoUser({
			Username: this.state.email,
			Pool: UserPool
		});

		const authDetails = new AuthenticationDetails({
			Username: this.state.email,
			Password: this.state.password,
		})

		user.authenticateUser(authDetails, {
			onSuccess: (data) => {
				const win: Window = window;
				console.log("success login");
				return win.location = '/dashboard';
			},
			onFailure: (data) => {
				console.error("failed to login: "+ data);
			}
		})
		
	}
	
	render() {
		return (
			<>
				<WholeSignInContainer>
					<NavContainer>
						<Navbar />
					</NavContainer>
					<SignInContainerLeft>
					<Form style={{width: '50%', textAlign: 'center', position: 'relative', alignItems: 'center', justifyContent: 'center'}}>
						<SignInHeader style={{width: '100%', textAlign: 'center'}} >Sign In</SignInHeader>

						<Input style={{marginTop: '20px', width: '100%'}} placeholder="Email" size='big' icon={<Icon name='mail'/>} iconPosition='left' value={this.state.email} onChange={this.handleEmailChanged} />
                        <Input style={{marginTop: '20px', width: '100%'}} type="password" placeholder="Password" size='big' icon={<Icon name='mail'/>} iconPosition='left' value={this.state.password} onChange={this.handlePasswordChanged} />                           

						<CheckboxWrapper>
							<Checkbox type="checkbox" />
							<KeepMeSignedIn>Keep me signed in.</KeepMeSignedIn>
						</CheckboxWrapper>
						<Button style={{width: '100%'}} onClick={this.signIn} primary={true} fontBig={false} big={true}>
							Sign in
						</Button>
						<LinksWrapper>
							<ForgotUsername style={{width: '100%'}}
								href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstleyVEVO"
								target="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstleyVEVO"
							>
								Forgot your username?
							</ForgotUsername>
							<ForgotPassword style={{width: '100%'}}
								href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstleyVEVO"
								target="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstleyVEVO"
							>
								Forgot your password?
							</ForgotPassword>
						</LinksWrapper>
					</Form>
					</SignInContainerLeft>
					<SignInContainerRight>
						<img src={signUpIcon} alt="signUpIcon" />
					</SignInContainerRight>

				</WholeSignInContainer>
			</>
		);
	};

}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {}
  };
  
  function mapStateToProps(state: any) {
    return { }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(SignInPage)